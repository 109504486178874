import React from "react";
import "./style.scss";

const LogoPortrait = props => {
  const defaultColors = {
    j: "#166D90",
    c: "#29ABE2",
    text: "#fff"
  };

  const colors = Object.assign({}, defaultColors, props.colors || {});

  return (
    <svg
      version="1.1"
      className="portrait-logo"
      xmlns="https://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 384.58 384.58"
      enableBackground="new 0 0 384.58 384.58"
      xmlSpace="preserve"
    >
      <g className="logo-group">
        <g className="jim">
          <path
            className="j"
            fill={colors.text}
            d="M27.8,367.07c0,8.16-3.5,10.52-8.21,10.52c-1.52,0-2.18-0.16-3.04-0.31v-2.56
			c0.71,0.16,1.98,0.26,3.14,0.26c4.76,0,5.37-3.82,5.37-7.95v-26.58h2.74V367.07z"
          />
          <path
            className="i"
            fill={colors.text}
            d="M50.41,340.44v36.62h-2.74v-36.62H50.41z"
          />
          <path
            className="m_1_"
            fill={colors.text}
            d="M101.71,360.21c0-7.17,0.05-14.02,0.2-17.68h-0.15c-1.42,4.92-8.62,22.97-13.13,34.53h-2.69
			c-3.4-8.95-10.85-29.04-12.47-34.58h-0.1c0.25,4.19,0.35,12.71,0.35,18.99v15.59h-2.74v-36.62h4.11
			c4.87,12.56,11,28.99,12.32,33.12h0.1c0.81-2.67,7.91-20.93,12.88-33.12h4.06v36.62h-2.74V360.21z"
          />
        </g>
        <g className="camut">
          <path
            className="c"
            fill={colors.text}
            d="M179.68,367.28c-1.73,5.81-6.02,10.2-13.76,10.2c-10.52,0-16.06-8.37-16.06-18.68
			c0-9.89,5.44-18.89,16.11-18.89c7.9,0,12.56,4.4,13.76,10.52h-2.83c-1.67-4.76-4.76-7.9-11.09-7.9c-9.1,0-12.92,8.32-12.92,16.22
			c0,7.85,3.77,16.11,13.13,16.11c5.96,0,9-3.09,10.83-7.59H179.68z"
          />
          <path
            className="a"
            fill={colors.text}
            d="M201.44,364.4l-4.66,12.66h-2.88l13.45-36.62h3.35l14.02,36.62h-3.09l-4.76-12.66H201.44z
			 M215.94,361.78c-4.08-10.88-6.17-16.11-6.91-18.57h-0.05c-0.89,2.77-3.24,9.26-6.54,18.57H215.94z"
          />
          <path
            className="m"
            fill={colors.text}
            d="M273.38,360.21c0-7.17,0.05-14.02,0.21-17.68h-0.16c-1.46,4.92-8.89,22.97-13.55,34.53h-2.77
			c-3.51-8.95-11.2-29.04-12.87-34.58h-0.1c0.26,4.19,0.37,12.71,0.37,18.99v15.59h-2.83v-36.62h4.24
			c5.02,12.56,11.35,28.99,12.71,33.12h0.1c0.84-2.67,8.16-20.93,13.29-33.12h4.19v36.62h-2.83V360.21z"
          />
          <path
            className="u"
            fill={colors.text}
            d="M300.17,340.44v22.18c0,9.73,5.49,12.24,10.67,12.24c6.12,0,10.67-2.56,10.67-12.24v-22.18h2.88
			v22.03c0,11.46-6.07,15.02-13.66,15.02c-7.22,0-13.5-3.92-13.5-14.54v-22.5H300.17z"
          />
          <path
            className="t"
            fill={colors.text}
            d="M352.8,343.05h-12.3v-2.62h27.52v2.62h-12.35v34.01h-2.88V343.05z"
          />
        </g>
        <path
          className="j-all"
          fill={colors.j}
          d="M219.7,57.22c-4.47,0-8.81,0.3-13.04,0.88v100.39c0,8.88-0.8,16.95-2.37,24.24
		c-3.13,14.54-9.35,25.92-18.71,34.11c-2.85,2.49-6.08,4.71-9.65,6.7c-13.87,7.7-33.23,11.61-58.08,11.73
		c-0.39,0-0.77,0.01-1.17,0.01H16.55v47.81h100.14c22.84,0,42.71-2.36,59.68-7.02c18.45-5.07,33.47-12.88,44.97-23.47
		c5.52-5.08,10.35-10.85,14.49-17.32c7.89-12.31,13.26-27.13,16.14-44.45c1.66-9.96,2.49-20.73,2.49-32.35V57.22H219.7z"
        />
        <g>
          <path
            className="j-cutout"
            fill="none"
            d="M158.71,82.07c-7.53,7.78-13.43,17.07-17.69,27.86c-4.27,10.79-6.4,22.72-6.4,35.76
			c0,12.8,2.13,24.72,6.4,35.76c4.27,11.05,10.17,20.52,17.69,28.42c5.08,5.33,10.83,9.87,17.22,13.67c3.58-1.99,6.81-4.21,9.65-6.7
			c14.05-12.29,21.08-31.75,21.08-58.35V58.1c-7.39,1.02-14.41,2.92-21.03,5.71C175.21,68.21,166.24,74.29,158.71,82.07z"
          />
          <path
            className="j-shadow"
            opacity="0.25"
            d="M206.66,89.24V58.1c0,0,13.4-2.54,47.81-0.88L206.66,89.24z"
          />
        </g>
        <path
          className="c-bottom"
          fill={colors.c}
          d="M224.35,255.87c-10.57,9.73-24.14,17.3-40.38,22.56c11.29,3.1,23.19,4.67,35.73,4.67h148.33
		v-47.81H241.05c-0.48,0.81-0.98,1.61-1.48,2.39C235.25,244.44,230.12,250.55,224.35,255.87z"
        />
        <path
          className="c-top"
          fill={colors.c}
          d="M158.71,82.07c7.53-7.78,16.5-13.86,26.92-18.26c6.63-2.79,13.64-4.69,21.03-5.71
		c4.22-0.58,8.56-0.88,13.04-0.88h34.78h113.55V9.41H254.48H219.7c-4.43,0-8.77,0.19-13.04,0.54c-14.14,1.14-27.43,4.15-39.86,9.07
		c-16.19,6.4-30.25,15.43-42.17,27.11c-11.92,11.67-21.21,25.79-27.86,42.35c-6.65,16.56-9.98,34.89-9.98,54.96
		c0,19.83,3.32,38.28,9.98,55.34c4.6,11.82,10.5,22.5,17.64,32.09h2.27c0.18,0,0.36,0,0.54-0.01l0.6-0.01
		c22.7-0.11,40.77-3.49,53.79-10.04c-4.69-3.19-9-6.82-12.91-10.92c-7.53-7.91-13.43-17.38-17.69-28.42
		c-4.27-11.04-6.4-22.97-6.4-35.76c0-13.05,2.13-24.97,6.4-35.76C145.28,99.14,151.18,89.85,158.71,82.07z"
        />
      </g>
    </svg>
  );
};
export default LogoPortrait;
